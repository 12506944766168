import React from 'react';
import Navbar from './components/Navbar/Navbar.js';
import AboutPortfolio from './components/About/AboutPorfolio.js';
import WorkExperience from './components/WorkExperience/WorkExperience.js';
import Projects from './components/Projects/Projects.js';
import Skills from './components/Skills/Skills.js';
import Contact from './components/Contact/Contact.js';
import Footer from './components/Footer/Footer.js';

function App() {
  return (
    <main className="overflow-hidden">
       <Navbar/>
       <AboutPortfolio />
       <Skills />
       <WorkExperience/>
       <Projects/>
       <Contact/>
       <Footer/>
    </main>
  );
}

export default App;
