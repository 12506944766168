import { Mail, Phone, LocationMarker } from "heroicons-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin,  faGithub, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import './Contact.css';

export default function Contact(){
    const [name, setName] =useState('');
    const [email, setEmail] =useState('');
    const [message, setMessage] =useState('');
    const [subject, setSubject] =useState('');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm(
            "service_ogqido8",
            "template_01uehi3",
            form.current,
            "a1pV-w0mHAvwSjcg9"
        )
        .then(
            (result) =>{
                alert("Message Sent!");
                console.log(result.text);
            },
            (error) => {
                alert("Failed to send message");
                console.log(error.text);
            }
        );
        e.target.reset();
    }

    const text = 
    "Get in touch with me! Let's learn about each other";
    const styles={
        textColor:{
            color:'#2bb0ed',
        },
        textColorLight:{
            color:'#2f8079',
        },
        heading:{
            color:'#fff',
            fontSize:'35px',
            fontFamily:'sans-serif',
        },
        borderStyle:{
            borderBottom:'2px solid #253347',
        },
    }
    // function encode(data) {
    //     return Object.keys(data)
    //       .map(
    //         (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    //       )
    //       .join("&");
    //   }
    
    //   function handleSubmit(e) {
    //     e.preventDefault();
    //     fetch("/", {
    //       method: "POST",
    //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //       body: encode({ "form-name": "contact", name, email, message }),
    //     })
    //       .then(() => alert("Message sent!"))
    //       .catch((error) => alert(error));
    //   }
    // function handleSubmit(e){
    //     e.preventDefault();
    //     fetch("/",{
    //         method:'POST',
    //         headers:{"Content-Type":"application/json"},
    //         body:JSON.stringify({name, email, message})
    //     })
    //     .then(()=>alert("Message sent!"))
    //     .catch(error => alert(error));
    // }
    return(
        <section id="contact" className="w-full mx-auto bg-[#253347] p-7">
            <div className="flex w-full flex-col md:flex-row container mx-auto">
                <div className="cText flex flex-col md:w-1/2">
                    <p className="mb-12" style={styles.heading}>
                        {text}
                    </p>
                    <div className="flex flex-col">
                        <div className="flex flex-row mb-6">
                            <Mail className="text-[#2bb0ed]"/>
                            <p className="ml-4 text-white"><a href="mailto:hlayaminnaye1996@gmail.com">hlayaminnaye1996@gmail.com</a></p>
                        </div>
                        <div className="flex flex-row mb-6">
                            <Phone className="text-[#2bb0ed]"/>
                            <p className="ml-4 text-white"><a href="tel:+6596131611">+65 96131611</a></p>
                        </div>
                        <div className="flex flex-row mb-0">
                            <LocationMarker className="text-[#2bb0ed]"/>
                            <p className="ml-4 text-white">Singapore</p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-5 lg:mt-auto md:mt-auto justify-start md:justify-center md:mb-0 my-10">
                        <div className="socials">
                            <a href="https://www.linkedin.com/in/hlayaminaye/" target="_blank"><FontAwesomeIcon icon={faLinkedin} style={styles.textColor} className="text-3xl"></FontAwesomeIcon></a>
                        </div>
                        <div className="socials">
                            <a href="https://github.com/Christynn26" target="_blank"><FontAwesomeIcon icon={faGithub} style={styles.textColor}  className="text-3xl"></FontAwesomeIcon></a>
                        </div>
                        <div className="socials">
                            <a href="#" target="_blank"><FontAwesomeIcon icon={faFacebook} style={styles.textColor}  className="text-3xl"></FontAwesomeIcon></a>
                        </div>
                        <div className="socials">
                        <a href="#" target="_blank"><FontAwesomeIcon icon={faInstagram} style={styles.textColor}  className="text-3xl"></FontAwesomeIcon></a>
                        </div>
                    </div>
                </div>
                <div className="cForm w-full md:w-1/2 rounded-xl p-[50px] bg-white">
                    <form ref={form} onSubmit = {sendEmail}>
                        <div className="flex flex-col my-2">
                            <label htmlFor="name" className="text-[#253347]">Name</label>
                            <input 
                            type="text" id="name" name="from-name" style={styles.borderStyle}
                            className="outline-none focus:outline-none focus:bg-[#ffffff] border-none"
                            onChange={(e) => setName(e.target.value) }/>
                        </div>
                        <div className="flex flex-col my-2">
                            <label htmlFor="email" className="text-[#253347]">Email</label>
                            <input type="text" id="email" name="from-email" style={styles.borderStyle}
                            className="outline-none focus:outline-none focus:bg-[#ffffff] border-none"
                            onChange={(e) =>  setEmail(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col my-2">
                            <label htmlFor="subject" className="text-[#253347]">Subject</label>
                            <input type="text" id="subject" name="subject" style={styles.borderStyle}
                            className="outline-none focus:outline-none focus:bg-[#ffffff] border-none"
                            onChange={(e) =>  setSubject(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col my-2">
                            <label htmlFor="message" className="text-[#253347]">Message</label>
                            <textarea id="message" row="5" name="message"
                            className="outline-none focus:outline-none focus:bg-[#ffffff] border-none"
                            onChange={(e)=> setMessage(e.target.value)} style={styles.borderStyle}></textarea>
                        </div>
                        <div className="w-full send-button rounded-lg mt-10 bg-[#253347] text-white p-2 text-center">
                            <input type="submit" value="Send Message"/>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}