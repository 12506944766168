import { useState } from "react";
import Icons from "../Icon/Icons";
import Card from "../Card/Card";
import '../Card/Card.css';
import '../Projects/Projects.css';

export default function Projects() {

    return (
        <section id="projects" className="lg:container md:container md:mx-auto lg:my-[70px] lg:p-0 p-7">
            <div className="w-full proj-head flex items-start">
                <div className="w-1/2">
                    <h3 className="text-left relative sec-title text-[#333] block tracking-wide font-bold text-2xl mb-[70px]">Project Experiences</h3>
                </div>
                <div className="w-1/2 all-projs text-right">
                    <button className="bg-[#3081D0] rounded-md text-white text-sm">All Projects</button>
                </div>
            </div>
            <div className="w-full mx-auto flex flex-col justify-between space-10 lg:space-x-10 md:space-x-15 md:flex-row my-10">
                <Card>
                    <div className="my-img">
                        <img src="images/weatherapp.png" className="group-hover:scale-105 transition duration-500 ease-in-out" />
                    </div>

                    <div className="card-desc">
                        <h4 className="h4head text-left font-semibold text-gray text-md my-2">Weather App Integrated with Telegram Bot</h4>
                        <h5 className="text-sm text-[#494545] mb-3">Java, Spring Boot, React, MySQL</h5>
                        <p className="text-xs">Designed and implemented a weather application to get related weather info of a specific city.</p>

                    </div>
                    <div className="card-button flex mt-auto pl-[10px] mb-2">
                        <a className="gh my-2" href="https://github.com/Christynn26/weather-app_frontend" target="_blank">Go to Github</a>
                    </div>
                </Card>
                <Card>
                    <div className="my-img">
                        <img src="images/recipeapp.png" className="group-hover:scale-105 transition-transform duration-500 ease-in-out" />
                    </div>

                    <div className="card-desc">
                        <h4 className="h4head text-left font-semibold text-gray text-ms my-2">Recipe App</h4>
                        <h5 className="text-sm text-[#494545] mb-3">Java, Spring Boot, React, Tailwind, MySQL</h5>
                        <p className="text-xs">An application where we can find cooking recipes easily. Add the recipes that caught your eyes to favorite.</p>

                    </div>
                    <div className="card-button flex mt-auto pl-[10px] mb-2">
                        <a className="gh my-2" href="https://github.com/Christynn26/recipeapp-backend" target="_blank">Go to Github</a>
                    </div>
                </Card>
                <Card>
                    <div className="my-img">
                        <img src="images/bookstore.png" className="group-hover:scale-105 transition duration-500 ease-in-out" />
                    </div>

                    <div className="card-desc">
                        <h4 className="h4head text-left font-semibold text-gray text-md my-2">Bookstore (In progress)</h4>
                        <h5 className="text-sm text-[#494545] mb-3">Java, Spring Boot, React, MySQL</h5>
                        <p className="text-xs">A small e-commerce app to purchase books.</p>
                    </div>
                    <div className="card-button flex mt-auto pl-[10px] mb-2">
                        <a className="gh my-2" href="https://github.com/Christynn26/bookstore.git" target="_blank">Go to Github</a>
                    </div>
                </Card>
            </div>
        </section>
    );
}