//import * as Icons from 'heroicons-react';
import { MailOpen } from 'heroicons-react';
import Button from '../Button/Button';
import './AboutThis.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


export default function AboutPortfolio() {
    const styles = {
        textColor: {
            color: "#3081D0"
        },
        image: {
            borderRadius: '50%',
            height: '250px',
            width: '250px',
            padding: '7px',
            backgroundColor: '#fff',
        },

        imageAfter: {
            content: '',
            position: 'absolute',
            backgroundImage: '',
            visibility: 'visible',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            zIndex: '-1',
            width: '330px',
            height: '330px',
            top: '0',
            left: '0',
            right: '0',
            margin: 'auto'
        },
        h3_wrapper: {
            position: 'relative',
            paddingLeft: '4%',
        },
        h3Span: {
            position: 'absolute',
            width: '3%',
            height: '4px',
            background: 'rgb(48, 129, 208)',
            left: '0px',
            top: '50%',
            fontSize: '23px'
        }
    }
    return (
        <section id="aboutport" className="mx-auto bg-[#f7ece1] p-7">

            <div className="flex flex-col md:flex-row justify-between mx-auto ">
                <div className="img-div relative md:grow-0 md:w-2/5 p-[50px] z-[1]">
                    <img
                        style={styles.image}
                        src='images/hyma_3.jpg'
                        alt="chris"
                        className="object-cover mx-auto" />

                </div>
                <div className="grow relative z-[1] md:w-3/5 font-inter flex flex-col justify-center flex-wrap">
                    <h1 className='mb-3 lg:text-6xl md:text-4xl text-3xl font-bold '>Hello! I'm Christine. </h1>
                    <h3 className="mb-3 lg:text-4xl md:text-3xl text-2xl font-bold text-[#253347]" style={styles.h3_wrapper}><span style={styles.h3Span}></span>Software Engineer</h3>
                    <div className="">
                        <p className='text-sm'> An NUS - ISS alumus passionate about software development.</p>
                        <p className='text-sm'> A softare engineer with a background in IT as a Juinor Web Developer & 5 months as a Software Developer Intern.</p>
                    </div>
                    <div className="flex flex-row gap-3 my-5 flex-wrap">
                        <div>
                            <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
                            <span className="ml-1 text-sm text-slate-700"> <a href="https://www.linkedin.com/in/hlayaminaye/" target='_blank'>hlayaminaye</a></span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>
                            <span className="ml-1 text-sm text-slate-700"><a href="https://github.com/Christynn26" target='_blank'>Christynn26</a></span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                            <span className="ml-1 text-sm text-slate-700"> <a href="mailto:hlayaminnaye1996@gmail.com">hlayaminnaye1996@gmail.com</a></span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                            <span className="ml-1 text-sm text-slate-700"> <a href="tel:+6596131611">+65 96131611</a></span>
                        </div>

                    </div>
                    <Button>Download CV</Button>

                </div>

                {/* <div className="img-div relative md:grow-0 md:w-1/3 my-5 p-10">
            <h4 className="text-left text-3xl font-bold my-3">About Me</h4>
                <p className="text-sm">I'm a detail-oriented front-end developer proficient in HTML, CSS, React, and Tailwind CSS, with backend experience in Java and C#. With a commitment to continuous learning, I'm ready to make a positive impact on any team or organization.</p>
            </div> */}
            </div>
        </section>

    );
}

