import React, { useState} from 'react';

export default function Button({children, onClick}){ 
    const handleDownload = () => {
        let filePath="documents/HlaYaminAye_Resume.pdf";
        const a = document.createElement('a');
        a.href= filePath;
        a.download="HlaYaminAye_Resume.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const [isHovered , setHover ] = useState(false);
    const styles = {
        wrapper:{
            position:'relative',
            display:'inline-block',
            width:'fit-content',
            overflow:'hidden'
        },
        button:{
            position:'relative',
            border:'2px solid #3081D0',
            // borderRadius: isHovered ? 'none' : '14px',
            color: isHovered ? '#ffffff' : '#3081D0',
            fontSize: '16.5px',
            padding:'10px 25px',
            transition: '0.3s',    
            zIndex:'9' 
        },
        beforeElement:{
            position:'absolute',
            content:'',
            bottom: '0',
            left: isHovered ? 'auto' : '0',
            width: '100%',
            height: isHovered ? '100%' : '0%',
            backgroundColor: isHovered ? '#3081D0' : '#f3f3f3',
            zIndex: isHovered ? '1' :'0',
            transition: '0.35s',
            visibility: isHovered ? 'visible' : 'none',
            color: '#fff !important',
        }
    }
    return(
        <div style={styles.wrapper}
        onMouseOver = {() => setHover(true)}
        onMouseOut={() => setHover(false)}
        >
            <div style={styles.beforeElement}
            onMouseOver = {() => setHover(true)}
            onMouseOut={() => setHover(false)}
            >
            </div>
            <button onClick={handleDownload}
            style={styles.button} 
            
            > {children}
            </button>
        </div>
    );
}