import './Skills.css';
import Icons2 from "../Icon/Icons2";

export default function Skills(){
    return (
        <section id="skills" className="md:container md:mx-auto lg:my-[70px] lg:p-0 p-7">
            <h3 className="text-left relative sec-title text-[#333] w-full block tracking-wide font-bold text-2xl mb-[70px]">Skills</h3>
            <div className="w-full skill-div md:flex justify-center gap-3 sm:block xs:block md:flex-row my-10 text-left">
                <div className="flex md:w-1/4 md:flex-col justify-center align-center p-10 mb-5">
                    <div className="">
                        <p>Seeking roles where i can apply my skills gained from academic projects and previous work experience for your organization.</p>
                    </div>
                </div>
                <div className="flex md:w-1/4 md:flex-col p-10 mb-5">
                    <div className="flex flex-col align-center justify-center mx-auto">
                        <Icons2 size={64} imgUrl={"icons/frontend.png"}/>
                        <h4 className="text-md my-3 font-bold text-[#3081D0]">Fontend</h4>
                        <p className="text-sm">HMTL, CSS, Bootstrap, Javascript, TailwindCSS, React</p>
                    </div>   
                </div>

                <div className="flex md:w-1/4 md:flex-col p-10 mb-5">
                    <div className="flex jutify-center align-center flex-col">
                        <Icons2 size={64} imgUrl={"icons/backend.png"}/>
                        <h4 className="text-md my-3 font-bold text-[#3081D0]">Backend and Databases</h4>
                        <p className="text-sm">C#, ASP.NET, Java, Spring boot, MYSQL, MSSQL</p>
                    </div>
                </div>

                <div className="flex md:w-1/4 md:flex-col p-10 mb-5">
                    <div className="flex jutify-center align-center flex-col">
                        <Icons2 size={64} imgUrl={"icons/lightbulb.png"}/>
                        <h4 className="text-md my-3 font-bold text-[#3081D0]">Tools and Technologies</h4>
                        <p className="text-sm">Docker, Git, Tortoise SVN</p>
                    </div>
                </div>
            </div>
        </section>
    );
}