import React, { useState } from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceAngry } from '@fortawesome/free-solid-svg-icons';

export default function Navbar(){
    const [navVisible, setNavVisible] = useState(false);
    const pageBody = document.querySelector("body");
    const toggleMenu = () => {
     setNavVisible(!navVisible);   
    };
    const svgClose = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 svg-close">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>;

    const svgBars = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 svg-bars">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
   </svg>;

    if(navVisible){
       pageBody.classList.add("no-scroll"); 
       
       
    }else{
        pageBody.classList.remove("no-scroll"); 

    }
    const closeOnMobile = () => {
        if(window.innerWidth <= 1150){
            setNavVisible(false);
        }
    };
    
    return(
        <header className="bg-[#f7ece1] static w-full mx-auto left-0 text-grey p-5 z-10 top-0 ">
            <div className="container lg:flex justify-between bg-dark mx-auto block">
                
                <div className="container nav-con flex lg:flex-row lg:justify-between justify-end items-end w-full flex-col-reverse">
                    <a href="/" className="transition relative self-start title-text ease-in-out title-font font-bold text-2xl text-center text-black hover:text-[#3081D0]">Christynn</a>
                    <nav className={`lg:w-2/3 flex lg:flex-row lg:justify-end lg:items-center ${navVisible ? 'nav-visible' : ''} w-full flex-col items-start`}>
                        <a href="#skills" className="navitem transition ease-in-out no-underline lg:ml-10" onClick={closeOnMobile}>Skills</a>
                        <a href="#work" className="navitem transition ease-in-out no-underline lg:ml-10" onClick={closeOnMobile}>Work Experience</a>
                        <a href="#projects" className="navitem transition ease-in-out no-underline lg:ml-10" onClick={closeOnMobile}>Projects</a>
                        <a href="#contact" className="navitem transition ease-in-out no-underline lg:ml-10" onClick={closeOnMobile}>Contact</a>
                    </nav>
                    <a className="nb-icon lg:hidden flex flex-col justify-end" onClick={toggleMenu}>
                        {/* { navVisible ? svgClose : svgBars} */}
                        <span className={`${navVisible ?'close' : 'bars'}`}></span>
                        <span className={`${navVisible ?'close' : 'bars'}`}></span>
                        <span className={`${navVisible ?'close' : 'bars'}`}></span>
                    </a>
                </div>
            </div>
        </header>
    );
}