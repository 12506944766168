import './WorkExperience.css';
export default function WorkExperience(){
    return(
        <section id="work" className="md:container md:mx-auto lg:my-[70px] lg:p-0 p-7">
            <h3 className="text-left relative sec-title text-[#333] w-full block tracking-wide font-bold text-2xl mb-[70px]">Work Experience</h3>
            <div className="w-full work-div md:flex lg:flex-row md:flex-row justify-between gap-3 sm:block xs:block my-10 text-left">
                <div className="w-full h-inherit mb-5 lg:w-1/2 md:w-1/2 flex flex-col">
                    <h4 className="work-title relative font-semibold text-lg">Software Developer Intern</h4>
                    <h5 className="my-3 text-[#494545] text-md font-semibold">Responsibilities</h5>
                    <ul className="list-square text-xs">
                        <li>Enhancing functions such as Search functions, Export Excel functions</li>
                        <li>Improving the UI as per the client's request</li>
                        <li>New implementation of attachment tab in the WMS UI </li>
                        <li>Translation of system documentation into WMS screens</li>
                        <li>Database backup automation process using batch scripts</li>
                    </ul>
                    <div className="tech justify-self-end">
                        <h5 className="my-3 text-[#494545] text-md font-semibold">Technologies</h5>
                        <ul className="flex flex-row flex-wrap text-xs">
                            <li>C#</li>
                            <li>ASP.NET</li>
                            <li>Telerik UI</li>
                            <li>MSSQL</li>
                            <li>Tortoise SVN</li>
                        </ul>
                    </div>
                </div>
                <div className="w-full h-inherit mb-5 lg:w-1/2 md:w-1/2 flex flex-col">
                    <h4 className="work-title relative font-semibold text-lg">Junior Web Developer</h4>
                    <h5 className="my-3 text-[#494545] text-md font-semibold">Responsibilities</h5>
                    <ul className="list-square text-xs">
                        <li>Involved in developing porfolio websites</li>
                        <li>Communicated with clients for user requirements and data</li>
                        <li>Made sure every website developed was responsive</li>
                    </ul>
                    <div className="tech justify-self-end mt-auto">
                        <h5 className="my-3 text-[#494545] text-md font-semibold">Technologies</h5>
                        <ul className="flex flex-row flex-wrap text-xs">
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>Bootstrap</li>
                            <li>Javascript</li>
                            <li>jQuery</li>
                            <li>Workpress</li>
                            <li>PHP</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}