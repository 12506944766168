import '../Card/Card.css';
export default function Icons2({size, imgUrl}){
    return(
        <img
            className="icon-img inline"
            src={imgUrl}
            width={size}
            height={size}
            alt=""
        />
    );
}